import React, { LabelHTMLAttributes } from "react";

function Label({ children, ...props }: LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label {...props} className="font-semibold text-sm">
      {children}
    </label>
  );
}

export default Label;
